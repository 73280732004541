<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item style="cursor: auto;">
        <p class="is-size-5 has-text-dark">{{ title }}</p>
      </b-navbar-item>
    </template>

    <template #start>
    </template>

    <template #end>
      <b-navbar-dropdown right hoverable v-if="currentUser">
        <template #label>
          <fa-icon icon="user-circle" size="lg" />
        </template>

        <b-navbar-item tag="div">
          <div>
            <p class="has-text-grey">{{ currentUser.email }}</p>
            <p class="has-text-grey mt-1">{{ currentUser.department }}</p>
          </div>
        </b-navbar-item>
        
        <hr class="navbar-divider">
        
        <b-navbar-item @click="singOut">ログアウト</b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>  
</template>


<script>
import { auth } from '@/main'

export default {
  props: ["title"],
  data() {
    return {
      currentUser: this.$store.state.user,
    }
  },
  methods: {
    singOut() {
      auth.signOut()
    }
  },
}
</script>


<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid #dbdbdb;

  .navbar-brand .navbar-item {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
  }
}
</style>
