<template>
  <div class="page">
    <navbar title="SHIFT AWARD" />

    <div class="p-5">
      <div class="notification is-primary is-light">
        正しいURLから遷移し直してください。
      </div>
    </div>
  </div>
</template>


<script>
import Navbar from '@/components/navbar'

export default {
  components: { Navbar }
}
</script>


<style lang="scss" scoped>
.navbar {
  border-bottom: none;
}
</style>
